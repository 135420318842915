.projects{
    height: 100%;
    padding: 0;
    margin: auto;
    max-width: 1400px;
}
.content-hero{
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-hero-header{
    font-size: 5rem;
    font-size: var(--fs-content-hero);
    color: white;
    text-align: center;
}

.panel{
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, .5fr);
    grid-template-rows: repeat(2, auto);
    align-items: center;
    place-self: center;
    margin: 20px  auto;
    transition: transform .2s;
    width: 100%;
    height: 100%;
    @include for-size(tablet-only){
        display: flex;
        align-self: center;
        width: 90%;
    }
    &:nth-child(even){
        .panel-picture{
            grid-area: 1/7/1/12;
            @include for-size(tablet-only){
                display: none;
            }
        }
        .panel-description{
            grid-area: 1/2/1/8;
            margin-left: 0px;
            margin-right: 30px;
            padding: 20px;
            @include for-size(tablet-only){
                grid-area: 1/1/1/12;
                width: 100%;
                background-color: hsla(218,58%,16%,.90);
                border-radius: 10px;
                margin: 0;
            }
        }
        .project-title{
            text-align: left;
            padding-left: 50px;
            @include for-size(tablet-only){
                text-align: center;
                margin-left: 0px;
                padding-left: 0px;
            }
        }
        .tech-list{
            justify-content: flex-start;
            padding-left: 45px;
        }
        .project-links{
            justify-content: flex-start;
            padding-left: 50px;
            a{
                padding-left: 5px;
            }
        }
        .tech-list, .project-links{
            @include for-size(tablet-only){
                justify-content: flex-end;
                a{
                    padding-left: 5px;
                }
            }
        }
    }
    iframe{
        border-radius: 10px;
    }
}
.content-hr{
    grid-area: 2/2/2/12;
    width: 100%;
    height: 5px;
    margin: 25px 0px;
    border-radius: 10px;
    border-color: transparent;
    background:linear-gradient(-45deg, $background-color-5, $background-color-4, $background-color-3, $background-color-1);
    opacity: 1;
    @include for-size(tablet-only){
        display: none;
    }
}

.panel-description{
    position: relative;
    grid-area: 1/6/1/12;
    z-index: 2;
    padding: 20px;
    @include for-size(tablet-only){
        grid-area: 1/1/1/12;
        width: 100%;
        background-color: hsla(218,58%,16%,.90);
        border-radius: 10px;
    }
}

.project-title{
    text-align: right;
    font-size: 2.3rem;
    font-size: var(--fs-return-button);
    color: whitesmoke;
    margin-bottom: 5px;
    @include for-size(tablet-only){
        text-align: center;
    }
}
.project-description{
    position: relative;
    padding: 20px;
    margin-left: 50px;
    background-color: hsla(218,58%,16%,.95);
    border-radius: 10px;
    box-shadow: 0 10px 30px -15px hsl(218,58%,16%);
    p{
        color: whitesmoke;
    }
    @include for-size(tablet-only){
        margin: auto;
        background-color: hsla(218,58%,16%,0);
        box-shadow: none;
    }
}
.panel-description-img{
    display: none;
    position: relative;
    @include for-size(tablet-only){
        margin: auto;
        display: block;
    }
    img{
        margin: auto;
    }
}
.tech-list{
    color: whitesmoke;
    display: flex;
    list-style: none;
    justify-content: flex-end;
    flex-wrap: wrap;
    word-break: word-break;
    margin: 5px 0px 0px 0px;
    li{
        padding: 5px;
    }
    @include for-size(tablet-only){
        padding-left: 5px;
        li{
            font-size: .8rem;
            padding: 5px;
        }
    }
}
.panel-picture{
    position: relative;
    grid-area: 1/2/1/7;
    width: 100%;
    img{
        border-radius: 10px;
    }
    video{
        border-radius: 10px;
    }
    iframe{
        width: 100%;
    }
    @include for-size(tablet-only){
        display: none;
    }
    &:hover{
        z-index: 1000;
    }
}

.card-picture{
    grid-area: 1/4/1/8;
}
.project-links{
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    a{
        margin-left: 5px;
    }
}

.lightbox-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: transparent;
    span{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        color: whitesmoke;
        opacity: .95;
        font-family: $Header-font;
        font-size: 1.2rem;
        display: none;
        @include for-size(tablet-only){
            display: block;
        }
        @include for-size(small-phone-only){
            font-size: 1rem;
        }
    }

}

