
.contact-name input:focus + .contact-lable .contact-span, .contact-name input:valid + .contact-lable .contact-span{
    translate: 0% -140%;
    font-size: .65rem;
}

.contact-name input:focus + .contact-lable:after, .contact-name input:valid + .contact-lable:after{
    translate: 100%;
}

.contact-email input:focus + .contact-lable .contact-span, .contact-email input:valid + .contact-lable .contact-span{
    translate: 0% -140%;
    font-size: .65rem;
}

.contact-email input:focus + .contact-lable:after, .contact-email input:valid + .contact-lable:after{
    translate: 100%;
}

.contact-text textarea:focus + .contact-text-lable .text-span, .contact-text textarea:valid + .contact-text-lable .text-span{
    translate: 0% 0%;
    font-size: .65rem;
    opacity: .1;
}

.contact-text textarea:focus + .contact-text-lable:after, .contact-text textarea:valid + .contact-text-lable:after{
    translate: 100%;
}
