html, body{
    width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0;
    background-color: $background-color-5;
    // min-height: 100%;
    // background-color: red;
    // overflow: auto;
}
body{
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.wrapper{
background: rgb(0,1,38);
min-height: 100%;
min-width: 100%;
display: flex;
flex-direction: column;
flex-grow: 1;
scroll-behavior: smooth;
}

.main-content-wrapper{
    max-width: 100%;
}
.hero{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $background-color-1;
    background-image: linear-gradient(180deg, $background-color-5 10%, $background-color-4 25%, $background-color-3 85%, $background-color-1 100%);
}
.content{
    position: relative;
}

.custom-shape-divider-bottom-1668620482 {
    position: absolute;
    bottom: -104;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1668620482 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 54px;
}
.design-process{
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: $background-color-1;
    background-image: $backgroung-grad;
}
.design-content-wrapper{
    display: flex;
    position: relative;
    align-self: center;
    flex-direction: column;
    max-width: 1400px;
    padding: 15px;
}
#animation-fill{
    fill: url(#animation-svg) #fff;
}

#web-fill{
    fill: url(#web-svg) #fff;
}
#graphics-fill{
    fill: url(#graphics-svg) #fff;
}
#art-fill{
    fill: $background-color-5
}

.design-process{
    background-color: $background-color-1;
    background-image: linear-gradient(180deg, $background-color-5 10%, $background-color-4 25%, $background-color-3 85%, $background-color-1 100%);
}
#web{
    background: $background-color-1;
    background: linear-gradient(180deg, $background-color-1 98.5%, $background-color-2 99%);
}

#graphics{
    background-color: $background-color-2;
    background: linear-gradient(180deg, $background-color-2 98.5%, $background-color-3 99%);
}

#animation{
    background: $background-color-3;
    background: linear-gradient(180deg, $background-color-3 99%, $background-color-4 99%);
}
#arts{
    background-color: $background-color-4;
    background-image: linear-gradient(180deg, $background-color-4 99%, $background-color-5 99%);
}