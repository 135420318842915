.custom-shape-divider-top {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
.custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.custom-shape-divider-bottom {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.custom-shape-divider-top .shape-fill {
    fill: $background-color-4;
}
.custom-shape-divider-bottom .shape-fill {
    fill: $background-color-1;
}
.hero-img{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .text-container{
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        margin: 10px;
        @include for-size(tablet-only){
            flex-direction: row;
            margin: 20px auto;
            text-align: center;
            justify-content: center;
        }
        span{
            color: whitesmoke;
            text-transform: capitalize;
            font-size: 6rem;
            font-size: var(--fs-hero-name);
            line-height: 1;
        }
        span:first-of-type{
            @include for-size(tablet-only){
                padding-right: 10px;
            }
        }
    }
    @include for-size(large-phone-only){
        flex-direction: column;
    }
}

.selfie-img{
    border-radius: 50%;
    width: 300px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 20px;
    @include for-size(tablet-only){
       width: 200px;
       height: 100%;
    }
    @include for-size(large-phone-only){
        width: 300px;
        height: 100%;
        margin: auto;

    }
}
.social-links{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    @include for-size(large-phone-only){
        justify-content: space-evenly;
    }
    a{
        // @include for-size(tablet-only){
        //     padding-right: 15px;
        // }
        &:hover{
            svg{
                fill: $nav-color;
            }
        }
        svg{
            @include for-size(large-phone-only){
                width: 40px;
                height: 100%;
            }
        }
    }
}
.tech-skills-container{
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    h1{
        font-size: 2.5rem;
        color: whitesmoke;
        @include for-size(phone-only){
            font-size: 2rem;
        }
    }
}
.tech-skills{
    background-color: $background-color-5;
    max-width: 680px;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: .5rem;
    margin: .5rem;
    box-shadow: 0px 0px 5px $nav-color;
    @include for-size(large-phone-only){
        width: 95%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 1rem;
    }
    @include for-size(small-phone-only){
        display: flex;
        flex-direction: column;
    }
    span{
        color: whitesmoke;
        font-family: $Header-font;
        @include for-size(phone-only){
            font-size: .8rem;
        }
    }
}
.skill{
    background-color: $nav-color;
    display: flex;
    padding: .9rem;
    flex: 0 1 30%;
    align-items: center;
    border-radius: 5px;
    justify-content: space-evenly;
    margin: 1%;
    @include for-size(large-phone-only){
        padding: .5rem;
    };
    img{
        padding-right: .5rem;
        @include for-size(large-phone-only){
            width: 30px;
            height: 30px;
        }
    }
}

.hero-text{
    background-color: $background-color-5;
    display: flex;
    align-self: center;
    max-width: 680px;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    span{
        color: whitesmoke;
        font-size: 1.2rem;
    }
}