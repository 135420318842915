.web-hero{
    position: relative;
    min-height: 55vh;
    &::before{
        content:"";
        background-image: url('../images/heros/web-background.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.graphics-hero{
    position: relative;
    min-height: 55vh;
    &::before{
        content:"";
        background-image: url('../images/heros/Artboard_1.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        opacity: .2;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.arts-hero{
    position: relative;
    min-height: 55vh;
    margin-top: 0px;
    background-color: #0d2960;
    &::before{
        content:"";
        background-image: url('../images/heros/fine_arts_hero.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.animation-hero{
    position: relative;
    min-height: 55vh;
    &::before{
        content:"";
        background-image: url('../images/heros/animation_hero.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.green-home-hero{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    &::before{
        content:"";
        background-image: url('../images/greenhomeliving/green-home-living-xl.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .content-hero-header{
        position: relative;
        z-index: 2;
    }
}
.period-tracker-hero{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 65vh;
    &::before{
        content:"";
        background-image: url('../images/period-tracker/tracker-hero-xl.webp');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include for-size(small-desktop){
            background-image: url('../images/period-tracker/tracker-hero.webp');
        }
        @include for-size(tablet-only){
            background-image: url('../images/period-tracker/tracker-hero-mobile.webp');
        }
    }
}