.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    position: relative;
    background-color: $background-color-5;
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        align-items: center;
        width: 35%;
        border-radius: 15px;
        padding: 15px;
        margin: 25px;
        background-color: $background-color-2;
        @include for-size(tablet-only){
            min-width: 95%;
        }
        @include for-size(small-desktop){
            width: 50%;
        }
    }

    h1{
        margin-bottom: 15px;
        color: whitesmoke;
    }
    textarea{
        color: whitesmoke;
    }
    input:focus, textarea:focus{
        color: whitesmoke;
        border: none;
        outline: none;
    }
}

.contact-button{
    border: none;
    border-radius: 5px;
    width: 100px;
    background-color: whitesmoke;
    color: $background-color-1;
    &:hover{
        background-color: $background-color-5;
    }
}

.contact-name, .contact-email{
    width: 290px;
    position: relative;
    height: 35px;
    overflow: hidden;
    margin: 0px 10px 020px;
    font-family: $Copy-font;
    input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 1.2rem;
        background-color: transparent;
        color: whitesmoke;
    }
    label{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        pointer-events: none;
        border-bottom: 1px solid white;
        color: whitesmoke;
        transform: all 0.2s ease;
        &:after{
            content:"";
            position: absolute;
            height: 100%;
            width: 100%;
            border-bottom: 3px solid $nav-color;
            left: 0px;
            bottom: -1px;
            transform: translateX(-100%);
            transition: all 0.3s ease;
        }
        span{
            position: absolute;
            bottom: 0px;
            left: 4px;
        }
    }
    @include for-size(phone-only){
        max-width: 100%;
    }
}
.email-message{
    background-color: $background-color-5;
    min-width: 100%;
    border-radius: 5px;
    text-align: center;
    transition: .4s linear;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.contact-text{
    position: relative;
    overflow: hidden;
    width: 290px;
    textarea{
        width: 290px;
        height: 100px;
        background-color: transparent;
        border: none;
        @include for-size(phone-only){
            max-width: 100%;
        }
    }
    label{
        position: absolute;
        bottom: 98%;
        left: 0px;
        width: 100%;
        height: 100%;
        max-width: 290px;
        pointer-events: none;
        color:hsl(0,0%,50%);
        transform: all 0.2s ease;
        &:before{
            content: "";
            border-bottom: 2px solid white;
            position: absolute;
            height: 100%;
            width: 100%;
            bottom: -90%;
            overflow: hidden;
        }
        &:after{
            content:"";
            position: absolute;
            height: 100%;
            width: 100%;
            border-bottom: 3px solid $nav-color;
            left: 0px;
            bottom: -90%;
            transform: translateX(-100%);
            transition: all 0.3s ease;
            overflow: hidden;
        } 
    }
    span{
        position: absolute;
        color: whitesmoke;
        top:100%;
        left: 4px
    }
    @include for-size(phone-only){
        max-width: 100%;
    }
}
