.brainstorming, .design-development, .development-resources{
    display: flex;
    flex-direction: column;
    h1,h2{
        color: whitesmoke;
        font-size: 2.23rem;
        font-size: var(--fs-header);
        text-align: center;
    }
    p{
        color: whitesmoke;
        padding: 15px;
        font-size: 1.25rem;
    }
    a{
        color: whitesmoke;
    }
}

.design-carousel{
    display: grid;
    align-self: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    .carousel-root{
        max-width: 700px;
    }
    @include for-size(tablet-only){
        display: flex;
        flex-direction: column;

    }
}

#design-carousel-2{
    grid-template-columns: 1fr;
}
.tracker-carousel{
    max-width: 500px;
    display: flex;
    align-self: center;
    h1{
        text-align: center;
        color: white;
    }
}

.videos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    @include for-size(large-phone-only){
        flex-direction: column;
    };
}

.video{
    aspect-ratio: 16/9;
    width: 100%;
    max-width: 500px;
}

.dev-picture{
    max-width: 500px;
    width: 100%;
}