:root {
  --fs-hero-name: clamp(1rem, calc(5vw + 1rem), 6rem);
  --fs-content-hero: clamp(2rem, calc(5vw + 1rem), 5rem);
  --fs-header: clamp(1rem, calc(5vw + 1rem), 2.25rem);
  --fs-footer:clamp(.5rem, calc(2.5vw + .2rem), 1.5rem);
  --fs-return-button: clamp(1rem, calc(4.5vw + 3rem), 2.0rem);
  --fs-body: 1.25rem;
}

/* Font families */
@font-face {
  font-family: "IBM_Plex_Sans";
  src: url(./../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf);
  src: local("IBM_Plex_Sans"), url(./../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./../fonts/Roboto/Roboto-Light.ttf);
  src: local("Roboto"), url(./../fonts/Roboto/Roboto-Light.ttf) format("truetype");
  font-display: swap;
}
h1, h2, h3, h4, h5 {
  font-family: "IBM_Plex_Sans";
}

p, li, span {
  font-family: "Roboto";
}

.noScroll {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #000155 10%, #000140 25%, #000130 85%, #000110 100%);
}

::-webkit-scrollbar-thumb {
  background: rgba(245, 245, 245, 0.8);
  border-radius: 1rem;
}

/* CSS Reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.carousel {
  width: 100% !important;
  margin-top: 0px !important;
}
.carousel div img {
  -o-object-fit: contain;
     object-fit: contain;
}

.thumbs-wrapper {
  display: none;
}

.control-dots {
  margin: 0 !important;
}

html, body {
  width: 100%;
  min-width: 100%;
  height: 100%;
  margin: 0;
  background-color: #000155;
}

body {
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.wrapper {
  background: rgb(0, 1, 38);
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.main-content-wrapper {
  max-width: 100%;
}

.hero {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #000110;
  background-image: linear-gradient(180deg, #000155 10%, #000140 25%, #000130 85%, #000110 100%);
}

.content {
  position: relative;
}

.custom-shape-divider-bottom-1668620482 {
  position: absolute;
  bottom: -104;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1668620482 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 54px;
}

.design-process {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #000110;
  background-image: linear-gradient(180deg, #000155 10%, #000140 25%, #000130 85%, #000110 100%);
}

.design-content-wrapper {
  display: flex;
  position: relative;
  align-self: center;
  flex-direction: column;
  max-width: 1400px;
  padding: 15px;
}

#animation-fill {
  fill: url(#animation-svg) #fff;
}

#web-fill {
  fill: url(#web-svg) #fff;
}

#graphics-fill {
  fill: url(#graphics-svg) #fff;
}

#art-fill {
  fill: #000155;
}

.design-process {
  background-color: #000110;
  background-image: linear-gradient(180deg, #000155 10%, #000140 25%, #000130 85%, #000110 100%);
}

#web {
  background: #000110;
  background: linear-gradient(180deg, #000110 98.5%, #000120 99%);
}

#graphics {
  background-color: #000120;
  background: linear-gradient(180deg, #000120 98.5%, #000130 99%);
}

#animation {
  background: #000130;
  background: linear-gradient(180deg, #000130 99%, #000140 99%);
}

#arts {
  background-color: #000140;
  background-image: linear-gradient(180deg, #000140 99%, #000155 99%);
}

.contact-name input:focus + .contact-lable .contact-span, .contact-name input:valid + .contact-lable .contact-span {
  translate: 0% -140%;
  font-size: 0.65rem;
}

.contact-name input:focus + .contact-lable:after, .contact-name input:valid + .contact-lable:after {
  translate: 100%;
}

.contact-email input:focus + .contact-lable .contact-span, .contact-email input:valid + .contact-lable .contact-span {
  translate: 0% -140%;
  font-size: 0.65rem;
}

.contact-email input:focus + .contact-lable:after, .contact-email input:valid + .contact-lable:after {
  translate: 100%;
}

.contact-text textarea:focus + .contact-text-lable .text-span, .contact-text textarea:valid + .contact-text-lable .text-span {
  translate: 0% 0%;
  font-size: 0.65rem;
  opacity: 0.1;
}

.contact-text textarea:focus + .contact-text-lable:after, .contact-text textarea:valid + .contact-text-lable:after {
  translate: 100%;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #000155;
  height: 75px;
  padding: 1rem;
  position: sticky;
  top: 0px;
  z-index: 100;
  opacity: 0.9;
}
@media (max-width: 868px) {
  nav {
    opacity: 1;
  }
}

.Nav_Links {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 5px;
}
@media (max-width: 868px) {
  .Nav_Links {
    translate: -100%;
    flex-direction: column;
    position: fixed;
    top: 15px;
    left: 0px;
    z-index: 100;
    height: 100%;
    width: 100%;
    margin-top: 50px;
    justify-content: start;
    background-color: #000155;
    overflow: auto;
    opacity: 1;
    transition: cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
  }
}

.logo {
  padding: 20px;
  margin: 10px 0px;
}

.Nav_items {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
}

.Nav_Links > li {
  margin: 0 1rem;
  position: relative;
}
@media (max-width: 868px) {
  .Nav_Links > li {
    text-align: center;
  }
}
@media (max-width: 868px) {
  .Nav_Links > li:first-child {
    margin-top: 20px;
  }
}
@media (max-width: 868px) {
  .Nav_Links > li:not(:last-child) {
    margin-bottom: 20px;
  }
}
.Nav_Links > li a {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
}
@media (max-width: 868px) {
  .Nav_Links > li a {
    font-size: 2rem;
  }
}
.Nav_Links > li a:hover {
  color: lightblue;
}
@media screen and (orientation: landscape) and (max-width: 840px) and (min-width: 390px) {
  .Nav_Links > li a {
    font-size: 1.25rem;
  }
}

.Nav_Links li ul {
  position: absolute;
  list-style: none;
  margin-top: 0.2rem;
  left: 0px;
  background-color: #000155;
  padding: 0px;
  transition-duration: 0.5s;
  opacity: 0;
  display: none;
  z-index: 100;
  border-radius: 10px;
}
@media (max-width: 868px) {
  .Nav_Links li ul {
    flex-direction: column;
    position: static;
  }
}

.Nav_Links li button {
  border: none;
  background-color: transparent;
  color: whitesmoke;
  font-size: 1.4rem;
  padding: 0;
}
@media (max-width: 868px) {
  .Nav_Links li button {
    font-size: 2rem;
  }
}
@media screen and (orientation: landscape) and (max-width: 840px) and (min-width: 390px) {
  .Nav_Links li button {
    font-size: 1.25rem;
  }
}

.dropdown span {
  font-size: 0.8rem;
  padding: 3px;
}

.dropdown > a {
  pointer-events: none;
}

.dropdown:hover > ul, .dropdown:focus-within > ul, .Nav_Links .dropdown ul:hover, ul, .Nav_Links .dropdown ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

.nav_dropdown li {
  padding: 10px 10px;
}

.dropdown_link {
  padding: 20px 10px;
}

.Mobile_Nav_container {
  display: none;
  height: 100%;
  width: 30px;
  padding-right: 20px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 868px) {
  .Mobile_Nav_container {
    display: flex;
  }
}

#Mobile_Nav_toggle {
  display: none;
}

#Mobile_Nav_toggle:checked ~ .Nav_Links {
  translate: 0% !important;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}

.Mobile_Nav,
.Mobile_Nav::before,
.Mobile_Nav::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
@media (max-width: 868px) {
  .Mobile_Nav,
  .Mobile_Nav::before,
  .Mobile_Nav::after {
    display: flex;
  }
}

.Mobile_Nav::before {
  content: "";
  margin-top: -8px;
}

.Mobile_Nav::after {
  content: "";
  margin-top: 8px;
}

.Dropdown::hover .nav_dropdown {
  display: flex;
}

#Mobile_Nav_toggle:checked + .Mobile_Nav_container .Mobile_Nav::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#Mobile_Nav_toggle:checked + .Mobile_Nav_container .Mobile_Nav {
  background: rgba(255, 255, 255, 0);
}

#Mobile_Nav_toggle:checked + .Mobile_Nav_container .Mobile_Nav::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

footer {
  background-color: #000155;
  padding: 8px;
  margin-top: auto;
  text-align: center;
}
footer span {
  color: #fff;
  font-size: var(--fs-footer);
  font-family: "IBM_Plex_Sans";
}

.return-to-top {
  display: flex;
  justify-content: center;
}
.return-to-top a {
  text-decoration: none;
  font-size: var(--fs-return-button);
  color: whitesmoke;
  font-family: "IBM_Plex_Sans";
}

.custom-shape-divider-top {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-top .shape-fill {
  fill: #000140;
}

.custom-shape-divider-bottom .shape-fill {
  fill: #000110;
}

.hero-img {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.hero-img .text-container {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  margin: 10px;
}
@media (max-width: 868px) {
  .hero-img .text-container {
    flex-direction: row;
    margin: 20px auto;
    text-align: center;
    justify-content: center;
  }
}
.hero-img .text-container span {
  color: whitesmoke;
  text-transform: capitalize;
  font-size: 6rem;
  font-size: var(--fs-hero-name);
  line-height: 1;
}
@media (max-width: 868px) {
  .hero-img .text-container span:first-of-type {
    padding-right: 10px;
  }
}
@media (max-width: 535px) {
  .hero-img {
    flex-direction: column;
  }
}

.selfie-img {
  border-radius: 50%;
  width: 300px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 20px;
}
@media (max-width: 868px) {
  .selfie-img {
    width: 200px;
    height: 100%;
  }
}
@media (max-width: 535px) {
  .selfie-img {
    width: 300px;
    height: 100%;
    margin: auto;
  }
}

.social-links {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
@media (max-width: 535px) {
  .social-links {
    justify-content: space-evenly;
  }
}
.social-links a:hover svg {
  fill: #1A3461;
}
@media (max-width: 535px) {
  .social-links a svg {
    width: 40px;
    height: 100%;
  }
}

.tech-skills-container {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
}
.tech-skills-container h1 {
  font-size: 2.5rem;
  color: whitesmoke;
}
@media (max-width: 460px) {
  .tech-skills-container h1 {
    font-size: 2rem;
  }
}

.tech-skills {
  background-color: #000155;
  max-width: 680px;
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0px 0px 5px #1A3461;
}
@media (max-width: 535px) {
  .tech-skills {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
  }
}
@media (max-width: 300px) {
  .tech-skills {
    display: flex;
    flex-direction: column;
  }
}
.tech-skills span {
  color: whitesmoke;
  font-family: "IBM_Plex_Sans";
}
@media (max-width: 460px) {
  .tech-skills span {
    font-size: 0.8rem;
  }
}

.skill {
  background-color: #1A3461;
  display: flex;
  padding: 0.9rem;
  flex: 0 1 30%;
  align-items: center;
  border-radius: 5px;
  justify-content: space-evenly;
  margin: 1%;
}
@media (max-width: 535px) {
  .skill {
    padding: 0.5rem;
  }
}
.skill img {
  padding-right: 0.5rem;
}
@media (max-width: 535px) {
  .skill img {
    width: 30px;
    height: 30px;
  }
}

.hero-text {
  background-color: #000155;
  display: flex;
  align-self: center;
  max-width: 680px;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.hero-text span {
  color: whitesmoke;
  font-size: 1.2rem;
}

.web-hero {
  position: relative;
  min-height: 55vh;
}
.web-hero::before {
  content: "";
  background-image: url("../images/heros/web-background.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
}

.graphics-hero {
  position: relative;
  min-height: 55vh;
}
.graphics-hero::before {
  content: "";
  background-image: url("../images/heros/Artboard_1.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
}

.arts-hero {
  position: relative;
  min-height: 55vh;
  margin-top: 0px;
  background-color: #0d2960;
}
.arts-hero::before {
  content: "";
  background-image: url("../images/heros/fine_arts_hero.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
}

.animation-hero {
  position: relative;
  min-height: 55vh;
}
.animation-hero::before {
  content: "";
  background-image: url("../images/heros/animation_hero.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
}

.green-home-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}
.green-home-hero::before {
  content: "";
  background-image: url("../images/greenhomeliving/green-home-living-xl.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.green-home-hero .content-hero-header {
  position: relative;
  z-index: 2;
}

.period-tracker-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 65vh;
}
.period-tracker-hero::before {
  content: "";
  background-image: url("../images/period-tracker/tracker-hero-xl.webp");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1300px) {
  .period-tracker-hero::before {
    background-image: url("../images/period-tracker/tracker-hero.webp");
  }
}
@media (max-width: 868px) {
  .period-tracker-hero::before {
    background-image: url("../images/period-tracker/tracker-hero-mobile.webp");
  }
}

.projects {
  height: 100%;
  padding: 0;
  margin: auto;
  max-width: 1400px;
}

.content-hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-hero-header {
  font-size: 5rem;
  font-size: var(--fs-content-hero);
  color: white;
  text-align: center;
}

.panel {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 0.5fr);
  grid-template-rows: repeat(2, auto);
  align-items: center;
  place-self: center;
  margin: 20px auto;
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
}
@media (max-width: 868px) {
  .panel {
    display: flex;
    align-self: center;
    width: 90%;
  }
}
.panel:nth-child(even) .panel-picture {
  grid-area: 1/7/1/12;
}
@media (max-width: 868px) {
  .panel:nth-child(even) .panel-picture {
    display: none;
  }
}
.panel:nth-child(even) .panel-description {
  grid-area: 1/2/1/8;
  margin-left: 0px;
  margin-right: 30px;
  padding: 20px;
}
@media (max-width: 868px) {
  .panel:nth-child(even) .panel-description {
    grid-area: 1/1/1/12;
    width: 100%;
    background-color: hsla(218, 58%, 16%, 0.9);
    border-radius: 10px;
    margin: 0;
  }
}
.panel:nth-child(even) .project-title {
  text-align: left;
  padding-left: 50px;
}
@media (max-width: 868px) {
  .panel:nth-child(even) .project-title {
    text-align: center;
    margin-left: 0px;
    padding-left: 0px;
  }
}
.panel:nth-child(even) .tech-list {
  justify-content: flex-start;
  padding-left: 45px;
}
.panel:nth-child(even) .project-links {
  justify-content: flex-start;
  padding-left: 50px;
}
.panel:nth-child(even) .project-links a {
  padding-left: 5px;
}
@media (max-width: 868px) {
  .panel:nth-child(even) .tech-list, .panel:nth-child(even) .project-links {
    justify-content: flex-end;
  }
  .panel:nth-child(even) .tech-list a, .panel:nth-child(even) .project-links a {
    padding-left: 5px;
  }
}
.panel iframe {
  border-radius: 10px;
}

.content-hr {
  grid-area: 2/2/2/12;
  width: 100%;
  height: 5px;
  margin: 25px 0px;
  border-radius: 10px;
  border-color: transparent;
  background: linear-gradient(-45deg, #000155, #000140, #000130, #000110);
  opacity: 1;
}
@media (max-width: 868px) {
  .content-hr {
    display: none;
  }
}

.panel-description {
  position: relative;
  grid-area: 1/6/1/12;
  z-index: 2;
  padding: 20px;
}
@media (max-width: 868px) {
  .panel-description {
    grid-area: 1/1/1/12;
    width: 100%;
    background-color: hsla(218, 58%, 16%, 0.9);
    border-radius: 10px;
  }
}

.project-title {
  text-align: right;
  font-size: 2.3rem;
  font-size: var(--fs-return-button);
  color: whitesmoke;
  margin-bottom: 5px;
}
@media (max-width: 868px) {
  .project-title {
    text-align: center;
  }
}

.project-description {
  position: relative;
  padding: 20px;
  margin-left: 50px;
  background-color: hsla(218, 58%, 16%, 0.95);
  border-radius: 10px;
  box-shadow: 0 10px 30px -15px hsl(218, 58%, 16%);
}
.project-description p {
  color: whitesmoke;
}
@media (max-width: 868px) {
  .project-description {
    margin: auto;
    background-color: hsla(218, 58%, 16%, 0);
    box-shadow: none;
  }
}

.panel-description-img {
  display: none;
  position: relative;
}
@media (max-width: 868px) {
  .panel-description-img {
    margin: auto;
    display: block;
  }
}
.panel-description-img img {
  margin: auto;
}

.tech-list {
  color: whitesmoke;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  flex-wrap: wrap;
  word-break: word-break;
  margin: 5px 0px 0px 0px;
}
.tech-list li {
  padding: 5px;
}
@media (max-width: 868px) {
  .tech-list {
    padding-left: 5px;
  }
  .tech-list li {
    font-size: 0.8rem;
    padding: 5px;
  }
}

.panel-picture {
  position: relative;
  grid-area: 1/2/1/7;
  width: 100%;
}
.panel-picture img {
  border-radius: 10px;
}
.panel-picture video {
  border-radius: 10px;
}
.panel-picture iframe {
  width: 100%;
}
@media (max-width: 868px) {
  .panel-picture {
    display: none;
  }
}
.panel-picture:hover {
  z-index: 1000;
}

.card-picture {
  grid-area: 1/4/1/8;
}

.project-links {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.project-links a {
  margin-left: 5px;
}

.lightbox-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: transparent;
}
.lightbox-button span {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: whitesmoke;
  opacity: 0.95;
  font-family: "IBM_Plex_Sans";
  font-size: 1.2rem;
  display: none;
}
@media (max-width: 868px) {
  .lightbox-button span {
    display: block;
  }
}
@media (max-width: 300px) {
  .lightbox-button span {
    font-size: 1rem;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  position: relative;
  background-color: #000155;
}
.contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  width: 35%;
  border-radius: 15px;
  padding: 15px;
  margin: 25px;
  background-color: #000120;
}
@media (max-width: 868px) {
  .contact form {
    min-width: 95%;
  }
}
@media (max-width: 1300px) {
  .contact form {
    width: 50%;
  }
}
.contact h1 {
  margin-bottom: 15px;
  color: whitesmoke;
}
.contact textarea {
  color: whitesmoke;
}
.contact input:focus, .contact textarea:focus {
  color: whitesmoke;
  border: none;
  outline: none;
}

.contact-button {
  border: none;
  border-radius: 5px;
  width: 100px;
  background-color: whitesmoke;
  color: #000110;
}
.contact-button:hover {
  background-color: #000155;
}

.contact-name, .contact-email {
  width: 290px;
  position: relative;
  height: 35px;
  overflow: hidden;
  margin: 0px 10px 20px;
  font-family: "Roboto";
}
.contact-name input, .contact-email input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  background-color: transparent;
  color: whitesmoke;
}
.contact-name label, .contact-email label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  pointer-events: none;
  border-bottom: 1px solid white;
  color: whitesmoke;
  transform: all 0.2s ease;
}
.contact-name label:after, .contact-email label:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid #1A3461;
  left: 0px;
  bottom: -1px;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.contact-name label span, .contact-email label span {
  position: absolute;
  bottom: 0px;
  left: 4px;
}
@media (max-width: 460px) {
  .contact-name, .contact-email {
    max-width: 100%;
  }
}

.email-message {
  background-color: #000155;
  min-width: 100%;
  border-radius: 5px;
  text-align: center;
  transition: 0.4s linear;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-transition: background-color 600000s 0s, color 600000s 0s;
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.contact-text {
  position: relative;
  overflow: hidden;
  width: 290px;
}
.contact-text textarea {
  width: 290px;
  height: 100px;
  background-color: transparent;
  border: none;
}
@media (max-width: 460px) {
  .contact-text textarea {
    max-width: 100%;
  }
}
.contact-text label {
  position: absolute;
  bottom: 98%;
  left: 0px;
  width: 100%;
  height: 100%;
  max-width: 290px;
  pointer-events: none;
  color: hsl(0, 0%, 50%);
  transform: all 0.2s ease;
}
.contact-text label:before {
  content: "";
  border-bottom: 2px solid white;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: -90%;
  overflow: hidden;
}
.contact-text label:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid #1A3461;
  left: 0px;
  bottom: -90%;
  transform: translateX(-100%);
  transition: all 0.3s ease;
  overflow: hidden;
}
.contact-text span {
  position: absolute;
  color: whitesmoke;
  top: 100%;
  left: 4px;
}
@media (max-width: 460px) {
  .contact-text {
    max-width: 100%;
  }
}

.brainstorming, .design-development, .development-resources {
  display: flex;
  flex-direction: column;
}
.brainstorming h1, .brainstorming h2, .design-development h1, .design-development h2, .development-resources h1, .development-resources h2 {
  color: whitesmoke;
  font-size: 2.23rem;
  font-size: var(--fs-header);
  text-align: center;
}
.brainstorming p, .design-development p, .development-resources p {
  color: whitesmoke;
  padding: 15px;
  font-size: 1.25rem;
}
.brainstorming a, .design-development a, .development-resources a {
  color: whitesmoke;
}

.design-carousel {
  display: grid;
  align-self: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.design-carousel .carousel-root {
  max-width: 700px;
}
@media (max-width: 868px) {
  .design-carousel {
    display: flex;
    flex-direction: column;
  }
}

#design-carousel-2 {
  grid-template-columns: 1fr;
}

.tracker-carousel {
  max-width: 500px;
  display: flex;
  align-self: center;
}
.tracker-carousel h1 {
  text-align: center;
  color: white;
}

.videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}
@media (max-width: 535px) {
  .videos {
    flex-direction: column;
  }
}

.video {
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 500px;
}

.dev-picture {
  max-width: 500px;
  width: 100%;
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}

.ril__navButtons:hover {
  opacity: 1;
}

.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==") no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}

.ril__builtinButton:hover {
  opacity: 1;
}

.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}

.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=") no-repeat center;
}

.ril__zoomInButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+") no-repeat center;
}

.ril__zoomOutButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=") no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%, 19.999%, 100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}
.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ril__loadingCirclePoint::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}

.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}

.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}

.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}

.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}

.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}

.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}

.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}

.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}

.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}

.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}

.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}

.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}

.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}

.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}

.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}

.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}

.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}/*# sourceMappingURL=styles.css.map */